import DocumentListItem from '../DocumentListItem/DocumentListItem';
import React from 'react';
import styles from './documentList.module.css';
import { DocumentMeta } from '../../../api/prismic/models';

type DocumentListProps = { documents: DocumentMeta[] };

const DocumentList: React.FC<DocumentListProps> = ({
  documents,
}: DocumentListProps) => {
  return (
    <div>
      <div className={styles['document-list']}>
        {documents.map((document) => (
          <div key={document.id} className={styles['document-list-item']}>
            <DocumentListItem document={document} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentList;
