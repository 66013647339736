import moment from 'moment';
import { DocumentMeta } from '../../../api/prismic/models';
import { DocumentsAction } from './actions';
import { DocumentsState, initialState, SortBy } from './models';
import { orderBy } from 'lodash';

export const documentsReducer = (
  state: DocumentsState,
  action: DocumentsAction,
): DocumentsState => {
  if (action.type === 'ToggleSearch') {
    return {
      ...state,
      searchActive: action.payload,
      searchText: action.payload ? state.searchText : '',
    };
  }
  if (action.type === 'Load') {
    return {
      ...state,
      documents: action.payload.concat
        ? [...state.documents, ...action.payload.documents]
        : action.payload.documents,
      totalPages: action.payload.totalPages,
    };
  }

  if (action.type === 'SearchTextUpdated') {
    return {
      ...state,
      searchText: action.payload,
    };
  }

  if (action.type === 'LoadMore') {
    return {
      ...state,
      currentPage: action.payload,
    };
  }

  if (action.type === 'Sort') {
    return {
      ...state,
      sortBy: action.payload,
      documents: sort(state, action.payload),
    };
  }

  if (action.type === 'Reset') {
    return action.payload ? { ...state, ...action.payload } : initialState;
  }

  return state;
};

const sort = (state: DocumentsState, value: SortBy): DocumentMeta[] => {
  return orderBy(
    state.documents,
    (x) => moment(x.last_publication_date),
    value === SortBy.Desc ? 'desc' : 'asc',
  );
};
