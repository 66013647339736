import * as Prismic from '../../../api/prismic/models';
import icon from './icon-round.png';
import moment from 'moment';
import React from 'react';
import styles from './documentHeader.module.css';
import { RichText } from 'prismic-reactjs';

type DocumentHeaderProps = { document: Prismic.Document };

const DocumentHeader: React.FC<DocumentHeaderProps> = ({
  document,
}: DocumentHeaderProps) => {
  return (
    <>
      <div className={styles['document-header-container']}>
        <img
          src={
            document.type === 'video'
              ? document.data.video.thumbnail_url
              : document.data.banner.url
          }
        />
        <div className={styles['document-header']}>
          <RichText render={document.data.title} />
          <div className={styles['document-header-title']}>
            <img src={icon} className={styles['document-header-icon']} />
            <div className={styles['document-header-title-text']}>
              <div className={styles['document-header-title-text-company']}>
                My Healthwise life
              </div>
              <div className={styles['document-header-title-text-date']}>
                {moment(document.last_publication_date).format('MMMM Do YYYY')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentHeader;
