import React from 'react';
import { Category } from '../../api/prismic/models';

export type CategoryContextType = {
  categories: Category[];
  activeCategoryId: string | null;
  setActiveCategory: (value: string | null) => void;
};

export const CategoryContext = React.createContext<CategoryContextType>({
  categories: [],
  activeCategoryId: null,
  setActiveCategory: (_) => {},
});
