import moment from 'moment';
import React from 'react';
import styles from './documentListItem.module.css';
import { DocumentMeta } from '../../../api/prismic/models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RichText } from 'prismic-reactjs';
import { useHistory } from 'react-router-dom';
import {
  faBook,
  faPaperclip,
  faVideo,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

type navigate = (url: string) => void;

const onPressFor: (document: DocumentMeta, navigate: navigate) => () => void = (
  document,
  navigate,
) => () => {
  const { id } = document;
  switch (document.type) {
    case 'post':
    case 'video':
      return navigate(`/document/${id}`);
    case 'attachment':
      return document.data.body
        ? navigate(`/document/${id}`)
        : window.open(document.data.media.url, '_blank');
    default:
      return null;
  }
};

const imageUrlFor: (document: DocumentMeta) => string = (document) => {
  switch (document.type) {
    case 'post':
      return document.data.banner.url;
    case 'video':
      return document.data.video.thumbnail_url ?? '';
    case 'attachment':
      return document.data.banner.url;
  }
};

const iconFor: (document: DocumentMeta) => IconDefinition = (document) => {
  switch (document.type) {
    case 'post':
      return faBook;
    case 'video':
      return faVideo;
    case 'attachment':
      return faPaperclip;
  }
};

const imageFor: (document: DocumentMeta) => JSX.Element = (document) => {
  const url = imageUrlFor(document);
  return <img src={url} className={styles['document-list-item-image']} />;
};

type DocumentListItemProps = { document: DocumentMeta };

const DocumentListItem: React.FC<DocumentListItemProps> = ({
  document,
}: DocumentListItemProps) => {
  const history = useHistory();
  return (
    <div className={styles['document-list-item-container']}>
      <div
        className={styles['document-list-item-image-container']}
        onClick={onPressFor(document, (url) => history.push(url))}
      >
        {imageFor(document)}
      </div>
      <div className={styles['document-list-item-caption-container']}>
        <div className={styles['document-list-item-caption']}>
          <div className={styles['document-list-item-caption-text']}>
            <RichText render={document.data.title} />
          </div>
          <div className={styles['document-list-item-caption-published-date']}>
            {moment(document.last_publication_date).format('MMMM Do YYYY')}
          </div>
        </div>

        <FontAwesomeIcon
          icon={iconFor(document)}
          className={styles['document-list-item-type-icon']}
        />
      </div>
    </div>
  );
};

export default DocumentListItem;
