import React from 'react';
import styles from './header.module.css';
import { Category } from '../../../api/prismic/models';
import { useHistory } from 'react-router-dom';

type CategoryMenuProps = {
  category: Category;
  activeCategoryId: string | null;
};

const CategoryMenu: React.FC<CategoryMenuProps> = ({
  category,
  activeCategoryId,
}: CategoryMenuProps) => {
  const history = useHistory();
  return (
    <div
      key={category.id}
      onClick={() => {
        history.push(`/documents/${category.id}`);
      }}
    >
      <div
        className={`${styles['header-category']} ${
          activeCategoryId === category.id
            ? styles['header-category-active']
            : styles['header-category-non-active']
        }`}
      >
        {category.data.title}
      </div>
    </div>
  );
};

export default CategoryMenu;
