import React from 'react';
import styles from './error.module.css';

const Error: React.FC = () => {
  return (
    <div className={styles['error-container']}>
      <div className={styles['error']}>
        <div className={styles['error-title']}>Oops! Something went wrong!</div>
        <div className={styles['error-body']}>Please try again later.</div>
      </div>
    </div>
  );
};

export default Error;
