import Error from '../Error/Error';
import Header from '../Header/Header';
import React from 'react';
import { ErrorContext } from '../errorContext';

export class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {}

  render() {
    return (
      <ErrorContext.Provider
        value={{
          hasError: this.state.hasError,
          setHasError: (value: boolean) =>
            this.setState((_) => ({ hasError: value })),
        }}
      >
        {this.state.hasError && (
          <div>
            <Header />
            <div
              className="w-full container mx-auto"
              style={{ overflowAnchor: 'none' }}
            >
              <Error />
            </div>
          </div>
        )}

        {!this.state.hasError && this.props.children}
      </ErrorContext.Provider>
    );
  }
}
