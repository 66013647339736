import amplitude from 'amplitude-js';
import { AnalyticsEvent } from './models';
import { config } from '../../config';

export const initAmplitude = () => {
  amplitude.getInstance().init(config.analyticsApiKey);
};

export const track = (event: AnalyticsEvent) => {
  const eventName = event.eventName;
  delete event.eventName;

  amplitude.logEvent(eventName, event);
};
