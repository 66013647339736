import CategoryMenu from './CategoryMenu';
import React from 'react';
import styles from './header.module.css';
import { Category } from '../../../api/prismic/models';

type CategoriesMenuProps = {
  categories: Category[];
  activeCategoryId: string | null;
};

const CategoriesMenu: React.FC<CategoriesMenuProps> = ({
  categories,
  activeCategoryId,
}: CategoriesMenuProps) => {
  return (
    <div className={`${styles['header-menu-container']}`}>
      <div className={`${styles['header-menu']} container mx-auto`}>
        {categories.map((x, i) => (
          <CategoryMenu
            key={i}
            category={x}
            activeCategoryId={activeCategoryId}
          />
        ))}
      </div>
    </div>
  );
};

export default CategoriesMenu;
