import React from 'react';

export type ErrorContextType = {
  hasError: boolean;
  setHasError: (value: boolean) => void;
};

export const ErrorContext = React.createContext<ErrorContextType>({
  hasError: false,
  setHasError: (_) => {},
});
