import { DocumentMeta } from '../../../api/prismic/models';

export type DocumentsState = {
  documents: DocumentMeta[];
  searchActive: boolean;
  searchText: string;
  sortBy: SortBy;
  currentPage: number;
  totalPages: number;
};

export enum SortBy {
  Asc = 'Asc',
  Desc = 'Desc',
}

export const initialState: DocumentsState = {
  documents: [],
  searchActive: false,
  sortBy: SortBy.Desc,
  currentPage: 1,
  totalPages: 1,
  searchText: '',
};
