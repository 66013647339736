import DocumentList from '../DocumentList/DocumentList';
import LoadingIndicator from '../../App/Loader/LoadingIndicator';
import React, { useContext, useEffect } from 'react';
import styles from './documents.module.css';
import { CategoryContext } from '../../App/categoryContext';
import { debounce, isEmpty } from 'lodash';
import { documentsReducer } from './reducers';
import { ErrorContext } from '../../App/errorContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { initialState, SortBy } from './models';
import { trackPromise } from 'react-promise-tracker';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  getDocumentsMetaForCategory,
  getDocuments,
} from '../../../api/prismic/client';
import {
  faSortAmountDown,
  faSortAmountDownAlt,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

const debounceSearch = debounce((doSearch: () => void) => {
  doSearch();
}, 500);

const Documents: React.FC = () => {
  const { categoryId } = useParams();
  const location = useLocation();

  const history = useHistory();

  const { categories, setActiveCategory } = useContext(CategoryContext);
  const { setHasError } = useContext(ErrorContext);

  const [state, dispatch] = React.useReducer(documentsReducer, initialState);

  const loadDocuments = async (isFirstLoad: boolean) => {
    try {
      const pageNum = isFirstLoad ? undefined : state.currentPage;
      const documents =
        categoryId === 'all' || !isEmpty(state.searchText)
          ? await getDocuments(pageNum, state.searchText)
          : await getDocumentsMetaForCategory(categoryId, pageNum);
      dispatch({
        type: 'Load',
        payload: {
          documents: documents.documents,
          totalPages: documents.totalPages,
          concat: !isFirstLoad && state.currentPage > 1,
        },
      });
    } catch (e) {
      setHasError(true);
    }
  };

  if (!isEmpty(categories) && !categories.find((x) => x.id === categoryId)) {
    history.push('/notfound');
  }

  useEffect(() => {
    setActiveCategory(categoryId);
  }, []);

  useEffect(() => {
    if (state.currentPage > 1) {
      trackPromise(loadDocuments(false));
    }
  }, [state.currentPage]);

  useEffect(() => {
    debounceSearch(() => {
      dispatch({
        type: 'Reset',
        payload: {
          currentPage: 1,
          documents: [],
          totalPages: 1,
        },
      });

      trackPromise(loadDocuments(true));
    });
  }, [state.searchText]);

  const onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SearchTextUpdated', payload: e.target.value });
  };

  const activeCategory = categories.find((x) => x.id === categoryId);
  return (
    <div className={styles['documents-container']}>
      <nav>
        <div className={`${styles['documents-header']} container mx-auto`}>
          {activeCategory && (
            <div className={styles['documents-category-title']}>
              {activeCategory!.data.title}
            </div>
          )}

          <div className={styles['documents-toolbar']}>
            <FontAwesomeIcon
              icon={
                state.sortBy === SortBy.Desc
                  ? faSortAmountDown
                  : faSortAmountDownAlt
              }
              className={styles['documents-toolbar-button']}
              onClick={() =>
                dispatch({
                  type: 'Sort',
                  payload:
                    state.sortBy === SortBy.Desc ? SortBy.Asc : SortBy.Desc,
                })
              }
            />
            <FontAwesomeIcon
              icon={faSearch}
              className={styles['documents-toolbar-button']}
              onClick={() =>
                dispatch({ type: 'ToggleSearch', payload: !state.searchActive })
              }
            />
          </div>
        </div>
        <div className={styles['documents-search-container']}>
          {state.searchActive && (
            <div className={`${styles['documents-search']} container mx-auto`}>
              <input
                id="searchfield"
                placeholder="Search..."
                autoFocus={true}
                value={state.searchText}
                className={styles['documents-search-field']}
                onChange={onChangeHandle}
              />
              {!isEmpty(state.searchText) && (
                <FontAwesomeIcon
                  icon={faTimes}
                  className={styles['documents-search-icon']}
                  onClick={() => {
                    dispatch({ type: 'SearchTextUpdated', payload: '' });
                  }}
                ></FontAwesomeIcon>
              )}
            </div>
          )}
        </div>
      </nav>
      <DocumentList documents={state.documents} />
      <LoadingIndicator />

      {state.currentPage < state.totalPages && (
        <div className={styles['documents-load-more-container']}>
          <button
            className={styles['documents-load-more-button']}
            onClick={async () => {
              dispatch({
                type: 'LoadMore',
                payload: state.currentPage + 1,
              });
            }}
          >
            Load more...
          </button>
        </div>
      )}
    </div>
  );
};

export default Documents;
