import * as Prismic from '../../../api/prismic/models';
import DocumentHeader from '../DocumentHeader/DocumentHeader';
import React from 'react';
import SocialShare from '../SocialShare/SocialShare';
import styles from '../Document/document.module.css';
import { RichText } from 'prismic-reactjs';

type PostProps = { post: Prismic.Post };

const Post: React.FC<PostProps> = ({ post }: PostProps) => {
  return (
    <>
      <DocumentHeader document={post} />
      <div className={styles['document-content-container']}>
        <SocialShare document={post} />
        <RichText render={post.data.body} />
      </div>
    </>
  );
};

export default Post;
