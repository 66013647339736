import * as Prismic from '../../../api/prismic/models';
import DocumentHeader from '../DocumentHeader/DocumentHeader';
import React from 'react';
import SocialShare from '../SocialShare/SocialShare';
import styles from '../Document/document.module.css';
import { RichText } from 'prismic-reactjs';

type AttachmentProps = { attachment: Prismic.Attachment };

const Attachment: React.FC<AttachmentProps> = ({
  attachment,
}: AttachmentProps) => (
  <>
    <DocumentHeader document={attachment} />
    <div className={styles['document-content-container']}>
      <SocialShare document={attachment} />
      <RichText render={attachment.data.body} />
      <a
        href={attachment.data.media.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {attachment.data.cta}
      </a>
    </div>
  </>
);

export default Attachment;
