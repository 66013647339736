import CategoriesMenu from './CategoriesMenu';
import CategoryMenu from './CategoryMenu';
import styles from './header.module.css';
import { CategoryContext } from '../categoryContext';
import { config } from '../../../config';
import { debounce, differenceBy, take } from 'lodash';
import { Logo } from '../Logo/Logo';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import React, { useContext, useState, useMemo, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const Header: React.FC = () => {
  const { categories, activeCategoryId } = useContext(CategoryContext);
  const [showMenu, setShowMenu] = useState<boolean>(true);
  const [overlayHeight, setOverlayHeight] = useState<number>(0);

  //const firstCategories = take(categories, config.maxCategoriesInMainMenu);
  // const restCategories = differenceBy(categories, firstCategories, 'id');
  // const showOverflowCategories = !!restCategories.length;

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y || currPos.y > -100;
      if (isShow !== showMenu) {
        setShowMenu(isShow);
        if (isShow) {
          updateDimensions();
        }
      }
    },
    [showMenu],
    undefined,
    false,
    300,
  );

  const menuRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const location = useLocation();
  const updateDimensions = () => {
    if (menuRef.current) {
      setOverlayHeight(menuRef.current?.clientHeight ?? 0);
    }
  };

  useEffect(() => {
    updateDimensions();
  }, [categories, location]);

  return useMemo(
    () => (
      <>
        <div className={styles['header-container']} ref={menuRef}>
          <div className={`${styles['header']} container mx-auto`}>
            <div className={styles['header-logo']}>
              <Logo />
            </div>
            <div className={styles['header-categories']}>
              {categories.map((x, i) => (
                <CategoryMenu
                  key={i}
                  category={x}
                  activeCategoryId={activeCategoryId}
                />
              ))}
            </div>
          </div>
          {showMenu && !!categories.length && (
            <div className="lg:hidden">
              <CategoriesMenu
                categories={categories}
                activeCategoryId={activeCategoryId}
              />
            </div>
          )}

          {/* {showMenu && showOverflowCategories && !!restCategories.length && (
            <div className="hidden lg:block">
              <CategoriesMenu
                categories={restCategories}
                activeCategoryId={activeCategoryId}
              />
            </div>
          )} */}
        </div>
        <div className={'w-full'} style={{ height: overlayHeight }}></div>
      </>
    ),
    [categories, showMenu, overlayHeight, activeCategoryId],
  );
};

export default Header;
