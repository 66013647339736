import React, { useContext } from 'react';
import styles from './footer.module.css';
import { CategoryContext } from '../categoryContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

const Footer: React.FC = () => {
  const { categories } = useContext(CategoryContext);
  const history = useHistory();
  return (
    <>
      <div className={styles['footer-container']}>
        <div className={`${styles['footer']} container mx-auto`}>
          <div className={styles['info']}>
            <div className={styles['about-us']}>
              <div className={styles['about-us-title']}>ABOUT HEALTHWISE</div>
              <div className={styles['about-us-content']}>
                <div>
                  Healthwise is here to help inspire and motivate you and your
                  family to live your happiest, healthiest life.
                </div>
                <div>
                  Check out some of our programs, events, challenges, workouts,
                  meditations and other info to help you succeed at whatever
                  your health & wellness goals may be.
                </div>
                <div>
                  Healthwise Global is located in Australia, New Zealand,
                  Canada, USA, United Kingdom, South Africa, Germany, Ireland,
                  Denmark, Finland, Iceland, Sweden, Hong Kong, China,
                  Singapore, Malaysia, Philippines, India, Mexico, Costa Rica,
                  Dominican Republic, Thailand, Bali, Vietnam, Indonesia,
                  Cambodia, Laos, Japan, UAE and constantly growing.
                </div>
              </div>
            </div>
            <div className={styles['categories']}>
              <div className={styles['categories-title']}>Categories</div>
              <div className={styles['categories-content']}>
                {categories.map((category) => (
                  <div
                    key={category.id}
                    className={styles['categories-link']}
                    onClick={() => {
                      history.push(`/documents/${category.id}`);
                    }}
                  >
                    <div className={styles['category-title']}>
                      {category.data.title}
                    </div>
                  </div>
                ))}
              </div>

              <div className={styles['social']}>
                <div className={styles['social-icon']}>
                  <FontAwesomeIcon
                    icon={faFacebook}
                    onClick={() => {
                      window.open(
                        'https://www.facebook.com/groups/547457389513782',
                        '_blank',
                      );
                    }}
                  />
                </div>

                <div className={styles['social-icon']}>
                  <FontAwesomeIcon
                    icon={faInstagram}
                    onClick={() => {
                      window.open(
                        'https://www.instagram.com/healthwise_global/',
                        '_blank',
                      );
                    }}
                  />
                </div>

                <div className={styles['social-icon']}>
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    onClick={() => {
                      window.open(
                        'https://www.linkedin.com/in/healthwise-global-29b89b143/',
                        '_blank',
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styles['copyright']}>
              <div className={styles['legal-disclaimer-container']}>
                <div className={styles['legal-disclaimer']}>
                  This is a guideline provided for informational purposes only.
                  We recommend seeking professional advice from your health care
                  provider should you have any related medical issues. This
                  content is not a substitute for professional medical advice.
                  You should always seek medical advice if feeling unwell.
                  Readers should also regularly check Government directions in
                  relation to COVID-19 to ensure compliance. No liability is
                  accepted for any injury, loss or damage suffered in reliance
                  on this site.
                </div>
              </div>
              <div className={styles['copyright-title']}>
                &copy; Copyright 2020&nbsp;&nbsp;
                <a
                  href="https://healthwiseglobal.com"
                  className="font-normal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Healthwise Global
                </a>
              </div>
              <div className={styles['terms-conditions']}>
                <a
                  href="https://healthwiseglobal.com"
                  className="font-normal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
