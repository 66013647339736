import * as Prismic from '../../../api/prismic/models';
import documentStyles from '../Document/document.module.css';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import SocialShare from '../SocialShare/SocialShare';
import styles from './video.module.css';
import { RichText } from 'prismic-reactjs';

type VideoProps = { video: Prismic.Video };

const Video: React.FC<VideoProps> = ({ video }: VideoProps) => (
  <div className={styles['document-video']}>
    <div className={documentStyles['document-content-container']}>
      <SocialShare document={video} />
      <iframe {...ReactHtmlParser(video.data.video.html)[0].props}></iframe>
      <RichText render={video.data.body} />
    </div>
  </div>
);

export default Video;
