import * as Prismic from '../../../api/prismic/models';
import Attachment from '../Attachment/Attachment';
import LoadingIndicator from '../../App/Loader/LoadingIndicator';
import Post from '../Post/Post';
import React, { useContext, useEffect, useState } from 'react';
import Video from '../Video/Video';
import { ErrorContext } from '../../App/errorContext';
import { getDocumentById } from '../../../api/prismic/client';
import { isEmpty } from 'lodash';
import { RichText } from 'prismic-reactjs';
import { track } from '../../../api/amplitude/client';
import { trackPromise } from 'react-promise-tracker';
import { useHistory, useParams } from 'react-router-dom';
import { UserViewsDocumentEvent } from '../../../api/amplitude/models';

const Document: React.FC = () => {
  const { documentId } = useParams();
  const history = useHistory();
  const [document, setDocument] = useState<Prismic.Document>();
  const { setHasError } = useContext(ErrorContext);

  const loadDocument = async () => {
    try {
      const doc = await getDocumentById(documentId);
      if (isEmpty(doc)) {
        history.push('/notfound');
        return;
      }
      setDocument(doc);
      const event: UserViewsDocumentEvent = {
        eventName: 'USER_VIEWS_DOCUMENT',
        id: documentId,
        title: RichText.asText(doc.data.title),
        type: doc.type,
      };
      track(event);
    } catch (e) {
      setHasError(true);
    }
  };

  useEffect(() => {
    trackPromise(loadDocument());
  }, []);

  return (
    <>
      <LoadingIndicator />
      {document ? getRender(document) : <></>}
    </>
  );
};

const getRender = (document: Prismic.Document) => {
  switch (document.type) {
    case 'post':
      return <Post post={document} />;
    case 'video':
      return <Video video={document} />;
    case 'attachment':
      return <Attachment attachment={document} />;
    default:
      return null;
  }
};

export default Document;
