import moment from 'moment';
import Prismic, { Predicates } from 'prismic-javascript';
import { maxBy, orderBy, trim } from 'lodash';
import { QueryOptions } from 'prismic-javascript/d.ts/ResolvedApi';
import {
  DocumentMetaKeys,
  DocumentMeta,
  Category,
  DocumentType,
  Document,
  DocumentsMeta,
} from './models';

const client = Prismic.client('https://healthwise.cdn.prismic.io/api/v2');

const getMetaProps = (documentType: DocumentType): DocumentMetaKeys[] => {
  switch (documentType) {
    case 'post': {
      return ['title', 'banner'];
    }
    case 'attachment': {
      return ['title', 'banner', 'media', 'body'];
    }
    case 'video': {
      return ['title', 'video', 'body'];
    }
    default: {
      return [];
    }
  }
};

const allMetaProps = [
  'post.title',
  'post.banner',
  'attachment.title',
  'attachment.banner',
  'attachment.media',
  'attachment.body',
  'video.title',
  'video.video',
  'video.body',
];

export const getDocumentsMetaForCategory = async (
  categoryId: string,
  pageIndex?: number,
): Promise<DocumentsMeta> => {
  const customTypes: DocumentType[] = ['post', 'attachment', 'video'];
  const allPromise = customTypes.map((t) => {
    const metaProps = getMetaProps(t).map((p) => `${t}.${p}`);
    const options: QueryOptions = {
      pageSize: 12,
      orderings: ['[document.first_publication_date desc]'],
      fetch: metaProps,
    };

    if (pageIndex) {
      options['page'] = pageIndex;
    }
    return client.query(
      [Predicates.at(`my.${t}.categories.category`, categoryId)],
      options,
    );
  });

  const allDocuments = await Promise.all(allPromise);

  return {
    documents: orderBy(
      allDocuments.flatMap((d) => (d.results as unknown) as DocumentMeta[]),
      (x) => moment(x.last_publication_date),
      'desc',
    ),
    totalPages: maxBy(allDocuments, (x) => x.total_pages)?.total_pages ?? 1,
    page: pageIndex ?? 1,
  };
};

export const getAllCategories = async (): Promise<Category[]> => {
  const { results } = await client.query(
    [Predicates.at('document.type', 'category')],
    {
      pageSize: 100,
      orderings: ['[document.first_publication_date desc]'],
    },
  );
  return results as Category[];
};

export const getDocumentById = async (
  documentId: string,
): Promise<Document> => {
  const matchedDocument = await client.getByID(documentId, {});
  return matchedDocument as Document;
};

const getDocumentsQueryOptions = (pageIndex?: number) => {
  const options: QueryOptions = {
    pageSize: 12,
    orderings: ['[document.first_publication_date desc]'],
    fetch: allMetaProps,
  };

  if (pageIndex) {
    options['page'] = pageIndex;
  }
  return options;
};

export const getDocuments = async (
  pageIndex?: number,
  searchText?: string,
): Promise<DocumentsMeta> => {
  const predicates = [Predicates.not('document.type', 'category')];

  if (trim(searchText)) {
    predicates.push(Predicates.fulltext('document', trim(searchText)));
  }
  const pages = await client.query(
    predicates,
    getDocumentsQueryOptions(pageIndex),
  );

  return {
    page: pages.page,
    totalPages: pages.total_pages,
    documents: pages.results as DocumentMeta[],
  };
};
