import React from 'react';
import styles from './notFound.module.css';

const NotFound: React.FC = () => {
  return (
    <div className={styles['not-found-container']}>
      <div className={styles['not-found']}>
        <div className={styles['not-found-title']}>
          Oops! We can&apos;t find that page.
        </div>
        <div className={styles['not-found-body']}>
          Why not check out our top categories from the menu.
        </div>
      </div>
    </div>
  );
};

export default NotFound;
