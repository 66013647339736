import Loader from 'react-loader-spinner';
import React from 'react';
import styles from './loadingIndicator.module.css';
import { usePromiseTracker } from 'react-promise-tracker';

const LoadingIndicator: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker();
  return promiseInProgress ? (
    <div className={styles['loader-container']}>
      <Loader type="ThreeDots" color="#00a4ad" />
    </div>
  ) : null;
};

export default LoadingIndicator;
