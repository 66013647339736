import * as Prismic from '../../../api/prismic/models';
import React from 'react';
import styles from './socialShare.module.css';
import { RichText } from 'prismic-reactjs';
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';

type PostProps = { document: Prismic.Document };

const SocialShare: React.FC<PostProps> = ({ document }: PostProps) => {
  return (
    <div className={styles['social-share']}>
      <TwitterShareButton
        url={window.location.href}
        title={`${RichText.asText(document.data.title)} @HW_Global `}
        className={styles['social-share-button']}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <FacebookShareButton
        url={window.location.href}
        title={RichText.asText(document.data.title)}
        className={styles['social-share-button']}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <LinkedinShareButton
        url={window.location.href}
        title={RichText.asText(document.data.title)}
        summary={RichText.asText(document.data.title)}
        source="My Healthwise Life"
        className={styles['social-share-button']}
      >
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
    </div>
  );
};

export default SocialShare;
