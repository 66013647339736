import Document from '../../Document/Document/Document';
import Documents from '../../Documents/Documents/Documents';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import NotFound from '../NotFound/NotFound';
import React, { useEffect, useState } from 'react';
import styles from './app.module.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Category } from '../../../api/prismic/models';
import { CategoryContext } from '../categoryContext';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { getAllCategories } from '../../../api/prismic/client';
import { initAmplitude } from '../../../api/amplitude/client';
import { sortBy } from 'lodash';
import { trackPromise } from 'react-promise-tracker';
import { ScrollToTop } from '../Scroll/ScrollToTop';

const App: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const loadCategories = async () => {
    const categories = await getAllCategories();
    categories.push({
      type: 'category',
      id: 'all',
      data: {
        title: 'All',
        order: -1,
      },
      href: '',
      tags: [],
      slugs: [],
      alternate_languages: [],
      first_publication_date: null,
      last_publication_date: null,
      uid: 'all',
    });
    setCategories(sortBy(categories, (x) => x.data.order));
  };

  useEffect(() => {
    trackPromise(loadCategories());
    initAmplitude();
  }, []);

  return (
    <BrowserRouter>
      <div style={{ overflowAnchor: 'none' }}>
        <ScrollToTop />
        <ErrorBoundary>
          <CategoryContext.Provider
            value={{
              categories,
              activeCategoryId: activeCategory,
              setActiveCategory,
            }}
          >
            <Header />
            <div className={`${styles['app-container']} container mx-auto`}>
              <div>
                <Switch>
                  <Route
                    path="/documents/:categoryId"
                    render={() => <Documents key={window.location.pathname} />}
                  ></Route>
                  <Route
                    path="/document/:documentId"
                    render={() => <Document key={window.location.pathname} />}
                  ></Route>
                  <Route
                    exact
                    path="/"
                    render={() => {
                      return <Redirect to="/documents/all" />;
                    }}
                  />
                  <Route path="/notfound">
                    <NotFound />
                  </Route>

                  <Route>
                    <Redirect to="/notfound" />
                  </Route>
                </Switch>
              </div>
            </div>
            <Footer />
          </CategoryContext.Provider>
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  );
};

export default App;
